import React, { useState, useEffect } from "react";
import Categorie from "../components/Categorie";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";

const Categories = () => {
    let { categorieId } = useParams();
    const [genre_ids, setGenreIds] = useState(categorieId ? categorieId : 28);
    const [listeCategories, setListeCategories] = useState([]);
    const [sortBy, setSortBy] = useState("");

    const changeCategorie = (e) => {
        setGenreIds(e.target.value);
    };

    const changeSortBy = (e) => {
        setSortBy(e.target.value);
    };

    // va chercher la liste des genres
    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/genre/movie/list?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA`
            )
            .then((res) => {
                setListeCategories(res.data.genres);
            });
    }, []);

    console.log(listeCategories);

    return (
        <>
            <div className="categorieswrapper">
                <div className="categoriestop menupadding">
                    <label
                        for="genres"
                        title="TITRE"
                        class="txt-open-sans txt-bold txt-40 txt-white txt-30-mobile mr-4"
                    >
                        Catégories
                    </label>
                    <select
                        class="select minimal"
                        name="genres"
                        id="genres"
                        value={genre_ids}
                        onChange={changeCategorie}
                    >
                        {listeCategories.map((cat) => (
                            <option
                                value={cat.id}
                                key={cat.id}
                                class="option p-3"
                            >
                                {cat.name}
                            </option>
                        ))}
                    </select>

                    <select
                        name="trie"
                        value={sortBy}
                        onChange={changeSortBy}
                        class="select minimal"
                    >
                        <option class="option p-3" value={""} disabled selected>
                            Trier par
                        </option>
                        <option class="option p-3" value={"popularity.desc"}>
                            Popularité +/-
                        </option>
                        <option class="option p-3" value={"popularity.asc"}>
                            Popularité -/+
                        </option>
                        <option class="option p-3" value={"vote_average.desc"}>
                            Note +/-
                        </option>
                        <option class="option p-3" value={"vote_average.asc"}>
                            Note -/+
                        </option>
                        <option class="option p-3" value={"release_date.desc"}>
                            Date de sortie +/-
                        </option>
                        <option class="option p-3" value={"release_date.asc"}>
                            Date de sortie -/+
                        </option>
                        <option class="option p-3" value={"original_title.asc"}>
                            Titre (de A à Z)
                        </option>
                        <option
                            class="option p-3"
                            value={"original_title.desc"}
                        >
                            Titre (de Z à A)
                        </option>
                    </select>
                </div>
                <div
                    class="categoriecontent"
                    style={{ backgroundImage: "url(/texture1.png)" }}
                >
                    {sortBy && (
                        <button
                            class="annuler-filtre"
                            type="button"
                            onClick={() => setSortBy("")}
                        >
                            Annuler le filtre
                        </button>
                    )}
                    {/* {filterTop === "nowPlaying" && <NowPlaying genreID={genre_ids} />} */}
                    <Categorie
                        genre_ids={genre_ids}
                        sortBy={sortBy}
                        //classListPoster={"col-4 col-lg-2 text-center"}
                        recherche={"categorie"}
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Categories;
