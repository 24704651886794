import axios from "axios";
import React, { useEffect, useState } from "react";
import Poster from "./Poster";

const NowPlaying = (props) => {
    const { counter } = props;
    const [nowPlaying, setNowPlaying] = useState([]);


    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/movie/now_playing?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA&region=CA&page=${counter}`
            )
            .then((res) => {
                setNowPlaying(res.data.results);
               
            });
    }, [counter]);
    return (
        <>
            <div className="categorie container-fluid  mx-0 sectionfilm">
                <div className="row row-cols-3 row-cols-lg-5 image-film  mx-0">
                    {nowPlaying.map((film) => (
                        <Poster {...film} key={film.id} width={300} />
                    ))}
                </div>
            </div>
        </>
    );
};

export default NowPlaying;
