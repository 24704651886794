// bas de page, présent dans toutes les pages
// 
import React from "react";
import { Image } from "react-bootstrap";

const Footer = () => {
  return <div className="col footer bg-rideaux" style={{backgroundImage: 'url(/arriere_plan_rideaux.jpg)'}}>
    <img src="/logo.png" className="logofooter" alt="CMC FILMS" />
      <p className="txt-18 txt-gold">© 2022 CMC inc.</p>
    </div>;
};

export default Footer;
