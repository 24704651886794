import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import axios from "axios";
import SearchBar from "./SearchBar2";
import { Navbar } from "react-bootstrap";

const Navigation = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <Navbar
                expand="lg"
                // bg="dark"
                className="mx-0  d-flex bg-black menupadding"
                // sticky="top"
            >
                <Navbar.Toggle
                    className="navbar-toggler navbar-toggler-icon mx-4"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    style={{backgroundImage: 'url(/menu_icon.png)'}}
                >
                    <span class="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Brand className="mx-lg-auto " href="/" title="TMDB">
                    <img src="/logo.png" className="logo" alt="logo" />
                </Navbar.Brand>
                <div className="menu-item-align-fa text-center order-lg-3">
                    <ul className="navbar-nav navbar-nav-droit mr-lg-auto" style={{ display: "block" }}>
                        <li className="nav-item ">
                            <a
                                href="#"
                                title="SEARCH"
                                className="icon-link"
                                data-toggle="collapse"
                                data-target="#navbarToggleExternalContent"
                                aria-controls="navbarToggleExternalContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </a>
                        </li>
                        
                        <li className="nav-item " onClick={() => setShowModal(true)}>
                            <a href="#" title="USER" class="icon-link">
                                <i className="fa-solid fa-circle-user"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <a
                                className="nav-link btn-link btn-active"
                                href="/nouveautes"
                                title="Nouveautés"
                            >
                                Nouveautés
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link btn-link"
                                href="/films/1"
                                title="Films"
                            >
                                Films
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link btn-link"
                                href="/#"
                                title="Films"
                            >
                                Séries
                            </a>
                        </li>
                        
                        <li class="nav-item">
                            <a
                                className="nav-link btn-link"
                                href="/categorie/1"
                                title="Catégories"
                            >
                                Catégories
                            </a>
                        </li>
                    
                    </ul>
                </div>
            </Navbar>
            <SearchBar />



            <Modal
                            show={showModal}
                            onHide={() => setShowModal(false)}
                            className="text-center fluid px-0 py-0"
                            size="xl"
                            centered={true}
                        >
                            <Modal.Body style={{ backgroundColor: "black" }}>
                            <div id="id01" class="modalform">
  
  <form class="modal-content animate formulairemodal  bg-white" action="/action_page.php" method="post">
    <div class="containerform">
      <label for="uname"><b>Nom d'utilisateur</b></label>
      <input type="text" placeholder="Enter Username" name="uname" required/>

      <label for="psw"><b>Mot de passe</b></label>
      <input type="password" placeholder="Enter Password" name="psw" required/>
        
      <button type="submit" class="formconnexion formbutton">Connexion</button>
    </div>

    <div class="containerform">

      <span class="psw"><a href="#">Mot de passe oublié?</a></span>
    </div>
  </form>
</div>
                            </Modal.Body>
                        </Modal>
        </>
    );
};

export default Navigation;
