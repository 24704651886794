import React from "react";
import Hero from "../components/Hero";
import Caroussel from "../components/Caroussel";
import Footer from "../components/Footer";

const Accueil = () => {
    return (
        <>
            <Hero />
            <Caroussel
                subject={"movie/popular"}
                slideNbr={5}
                title={"Films Populaires"}
            />
            <div className="bg-rideaux" style={{backgroundImage: 'url(/arriere_plan_rideaux.jpg)'}}>
            <Caroussel
                subject={"discover/movie"}
                withGenres={10751}
                slideNbr={5}
                title={"Jeunesse"}
                isJeunesse={true}
            />
            </div>
            <Caroussel
                subject={"genre/movie/list"}
                slideNbr={4}
                title={"Catégories"}
            />
            <Caroussel
                subject={"person/popular"}
                slideNbr={5}
                title={"Acteurs Populaires"}
            />
            <Footer />
        </>
    );
};

export default Accueil;
