// Phot de profile des acteurs, resizable
import React from "react";

const Profile = (props) => {
    const { profile_path, width, name, id,  } = props;
    let src = "https://image.tmdb.org/t/p/w" + width + profile_path;
    let style;
    if (profile_path === null) {
        src = "/placeholder.jpg";
        style = { width: width };
    }
    return width <= 300 ? (
        <a href={`/${id}/ficheActeur`}>
            <img class="mw-100 px-1 image-film-poster" src={src} alt={name} style={style} />
        </a>
    ) : (
        <img class="mw-100 px-1 image-film-poster" src={src} alt={name} />
    );
};

export default Profile;
