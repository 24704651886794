import axios from "axios";
import React, { useEffect, useState } from "react";

const ActeurInfo = (props) => {
    const { name, birthday, biography, place_of_birth } = props;
    const [bioWiki, setBioWiki] = useState("");

    useEffect(() => {
        axios
            .get(
                `https://fr.wikipedia.org/w/api.php?action=query&prop=extracts&titles=${name}&formatversion=2&format=json&&origin=*&disablepp`
            )
            .then((res) => setBioWiki(res.data.query.pages[0].extract));
    }, [biography]);

    console.log(bioWiki);
    return (
        <div class="col-12 col-lg-8">
            <h2 class="mr-4 align-bottom txt-bold pb-2 txt-center-mobile txt-white">{name}</h2>
            <div class="py-2 txt-center-mobile">
              <a href="#" class="txt-gold txt-40 icone_sociaux_margin" title="Twitter"><i class="fa-brands fa-twitter-square"></i></a>
              <a href="#" class="txt-gold txt-40 icone_sociaux_margin" title="Facebook"><i class="fa-brands fa-facebook-square"></i></a>
              <a href="#" class="txt-gold txt-40 icone_sociaux_margin" title="Instagram"><i class="fa-brands fa-instagram-square"></i></a>
            </div>

            <div class="py-4">
              <div class="row mx-0 ">
                <div class="col-auto px-1 txt-18 txt-white">
                  Date de naissance :
                </div>
                <div class="col px-1 txt-18 txt-white">
                {birthday}
                </div>
              </div>
              <div class="row mx-0 ">
                <div class="col col-auto px-1 txt-18 txt-white">
                  Lieu de naissance :  
                </div>
                <div class="col px-1 txt-18 txt-white">
                {place_of_birth}
                </div>
              </div>
            </div>

            <div class="pb-4">
            {biography && <div class="py-1 txt-18 txt-white">
                Biographie :
              </div>}
              
              {biography ? (
                <>
                    <p class="txt-18 txt-white <">{biography}</p>
                </>
            ) : (
                <>
                    {bioWiki ? (
                        <p class="force"
                            dangerouslySetInnerHTML={{
                                __html: bioWiki.slice(0, 2000) + "..."
                            }}
                        ></p>
                    ) : (
                        <h4>Biographie non disponible</h4>
                    )}
                </>
            )}

            </div>
        </div>
    );
};

export default ActeurInfo;
