import React, { useState, useEffect } from "react";
import axios from "axios";
import Poster from "./Poster";
// Carousel.js
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Profile from "./Profile";

const Caroussel = (props) => {
    const { slideNbr, subject, withGenres, title, isJeunesse } = props;
    const [pop, setPop] = useState([]);
    const [sliderRef, setSliderRef] = useState(null);

    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/${subject}?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-CA&page=1${
                    withGenres && `&with_genres=${withGenres}`
                }`
            )
            .then((res) =>
                subject === "genre/movie/list"
                    ? setPop(res.data.genres)
                    : setPop(res.data.results)
            );
    }, []);
    const sliderSettings = {
        slidesToShow: slideNbr,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        centerPadding: "60px",
        autoplay: false,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: slideNbr
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: slideNbr - 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    console.log(`slideNbr: ${slideNbr}`);

    return (
        <>
            <div class="container-fluid mx-0  section-site">
                <div class="row menupadding">
                    <div class="col">
                        <h2 class="txt-center-mobile section-padding-mobile">
                            <a
                                href="#"
                                title="TITRE"
                                class="txt-open-sans txt-bold txt-40 txt-white txt-30-mobile"
                            >
                                {title}
                            </a>
                        </h2>
                    </div>
                    <div class="col d-none d-lg-block">
                        <h2 class="txt-white txt-right">
                            <a
                                href={
                                    {
                                        "movie/popular": "/films",
                                        "discover/movie": `/categorie/1/${withGenres}`,
                                        "genre/movie/list": "/categorie"
                                    }[subject]
                                }
                                title="TITRE"
                                class="txt-nunito txt-bold txt-30 txt-white"
                            >
                                {subject !== "person/popular" && "Tout voir »"}
                            </a>
                        </h2>
                    </div>
                </div>

                <div class="row px-0 relative">
                    <span class="prev" onClick={sliderRef?.slickPrev}>
                        <i class="fa-solid fa-chevron-left"></i>
                    </span>
                    <span class="next" onClick={sliderRef?.slickNext}>
                        <i class="fa-solid fa-chevron-right"></i>
                    </span>
                    <Slider
                        ref={setSliderRef}
                        {...sliderSettings}
                        className={
                            (subject === "person/popular" || isJeunesse) &&
                            "slider-margin-effect"
                        }
                    >
                        {subject === "genre/movie/list"
                            ? pop.map((genre) => (
                                  <div className="text-center">
                                      <a
                                          href={`/categorie/1/${genre.id}`}
                                          title="CATEGORIE"
                                          class="billet lien"
                                          style={{
                                              backgroundImage:
                                                  "url(/billet.png)"
                                          }}
                                      >
                                          {genre.name}
                                      </a>
                                  </div>
                              ))
                            : subject === "person/popular"
                            ? pop.map((actor) => (
                                  <Profile
                                      {...actor}
                                      key={actor.id}
                                      width={300}
                                  />
                              ))
                            : pop.map((movie) => (
                                  <Poster
                                      {...movie}
                                      key={movie.id}
                                      width={300}
                                      classList={
                                          "col text-center px-1 imgspacer image-film"
                                      }
                                  />
                              ))}
                    </Slider>
                    <div class="container-fluid mx-0">
                        <div class="row">
                            <div class="col d-lg-none">
                                <h2 class="txt-white txt-right txt-center-mobile section-padding-mobile pb-0">
                                    <a
                                        href={
                                            {
                                                "movie/popular": "/films",
                                                "discover/movie": `/categorie/1/${withGenres}`,
                                                "genre/movie/list": "/categorie"
                                            }[subject]
                                        }
                                        title="TITRE"
                                        class="txt-nunito txt-bold txt-30 txt-white txt-22-mobile"
                                    >
                                        Tout voir »
                                    </a>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Caroussel;
// Caroussel utilisé pôur différents contextes ex: films par catégorie, acteurs par film
