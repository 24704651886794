import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Acteurs from "../components/Acteurs";
import Poster from "../components/Poster";
import Caroussel from "../components/Caroussel";
import Footer from "../components/Footer";

const FicheFilm = () => {
    const { id } = useParams();
    const [film, setFilm] = useState([]);
    const [overviewMissing, setOverviewMissing] = useState(false);
    const [filmAnglais, setFilmAnglais] = useState([]);
    const [wikiFilm, setWikiFilm] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/movie/${id}?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA&append_to_response=release_dates,credits,videos`
            )
            .then((res) => {
                setFilm(res.data);
                if (!res.data.overview) {
                    setOverviewMissing(true);
                }
            });
    }, [id]);

    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/movie/${id}?api_key=1761a627041b994f3b2489ffcaa486f0&language=en-US`
            )
            .then((res) => setFilmAnglais(res.data.title));
    }, [overviewMissing]);

    useEffect(() => {
        axios
            .get(
                `https://fr.wikipedia.org/w/api.php?action=query&prop=extracts&titles=${filmAnglais}&formatversion=2&format=json&&origin=*`
            )
            .then((res) => {
                console.log(res.data);
                if (res.data.query) {
                    setWikiFilm(res.data.query.pages[0].extract);
                }
            });
    }, [filmAnglais]);

    if (!film.genres) {
        return null;
    }
    console.log(film);

    return (
        <>
            <div className="fichefilmwrapper">
                <div className="film">
                    <div
                        className="fiche-films"
                        style={{
                            backgroundImage: `url(
                        https://image.tmdb.org/t/p/original${film.backdrop_path}
                    )`
                        }}
                    >
                        <div className="container-fluid mx-0 fiche-films bg-fiche-opacity">
                            <Row className="mx-0">
                                <div className="col-12 col-lg-4 text-center pb-3">
                                    <Poster
                                        {...film}
                                        classList={"mw-100 image-film-poster"}
                                        width={400}
                                    />
                                </div>
                                <Col lg={8}>
                                    <h2 className="mr-4 align-bottom txt-open-sans txt-bold txt-40 txt-white txt-30-mobile">
                                        {film.title}
                                    </h2>
                                    <div className="py-2">
                                        <span class="classement txt-18 txt-white">
                                            {film.release_dates.results.map(
                                                (pays) => (
                                                    <span>
                                                        {pays.iso_3166_1.includes(
                                                            "US"
                                                        ) &&
                                                            pays
                                                                .release_dates[0]
                                                                .certification}
                                                    </span>
                                                )
                                            )}
                                        </span>
                                        <span className="txt-18 txt-white">
                                            {film.release_date} -{" "}
                                            {Math.floor(film.runtime / 60)}h
                                            {film.runtime % 60} min
                                        </span>
                                    </div>
                                    <div
                                        className="py-2"
                                        style={{
                                            display: "flex",
                                            flexFlow: "row, wrap"
                                        }}
                                    >
                                        {film.genres.map((genre) => (
                                            <a
                                                className="genre txt-20 bg-gold"
                                                href={`/categorie/1/${genre.id}`}
                                                style={{
                                                    textDecoration: "none"
                                                }}
                                            >
                                                {genre.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="py-4 synopsis">
                                        {" "}
                                        {film.overview ? (
                                            <>
                                                <p>{film.overview}</p>
                                            </>
                                        ) : (
                                            <>
                                                {wikiFilm ? (
                                                    <>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    wikiFilm.slice(
                                                                        0,
                                                                        1000
                                                                    ) + "..."
                                                            }}
                                                        ></p>
                                                        <a
                                                            href={`https://fr.wikipedia.org/wiki/${filmAnglais}`}
                                                        >
                                                            Plus
                                                        </a>
                                                    </>
                                                ) : (
                                                    <h4>
                                                        Synopsis non disponible
                                                    </h4>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="synopsis">
                                        Durée : {Math.floor(film.runtime / 60)}h
                                        {film.runtime % 60} min
                                    </div>
                                    <div className="synopsis">
                                        Date de sortie : {film.release_date}
                                    </div>
                                    <div className="synopsis">
                                        Genre{film.genres.length > 1 && "s"} :{" "}
                                        {film.genres.map((genre) => (
                                            <span>
                                                {genre.name} {"  "}
                                            </span>
                                        ))}
                                    </div>
                                    <div className="synopsis">
                                        Classement :{" "}
                                        {film.release_dates.results.map(
                                            (pays) => (
                                                <span>
                                                    {pays.iso_3166_1.includes(
                                                        "US"
                                                    ) &&
                                                        pays.release_dates[0]
                                                            .certification}
                                                </span>
                                            )
                                        )}
                                    </div>
                                    {film.credits.crew && (
                                        <div className="synopsis">
                                            Réalisateur :{" "}
                                            {film.credits.crew.map(
                                                (crewMember) => (
                                                    <span>
                                                        {crewMember.job ===
                                                            "Director" &&
                                                            crewMember.name}
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {film.credits.cast[0].name && (
                                        <div className="synopsis">
                                            Distribution :{" "}
                                            {film.credits.cast[0].name},{" "}
                                            {film.credits.cast[1].name},{" "}
                                            {film.credits.cast[2].name},{" "}
                                            {film.credits.cast[3].name},{" "}
                                            {film.credits.cast[4].name} ...
                                        </div>
                                    )}
                                    <div className="py-4">
                                        <div
                                            onClick={() => setShowModal(true)}
                                            className="bouton-bande-annonce align-middle"
                                            title="BANDEANNONCE"
                                            target="_blank"
                                        >
                                            <i class="fa-solid fa-clapperboard"></i>{" "}
                                            Bande annonce
                                        </div>
                                        <div
                                            href="#"
                                            title="JAIME"
                                            class="bouton-coeur align-middle"
                                        >
                                            <i class="fa-solid fa-heart"></i>
                                        </div>
                                    </div>
                                </Col>
                                <Modal
                                    show={showModal}
                                    onHide={() => setShowModal(false)}
                                    className="text-center fluid px-0 py-0"
                                    size="xl"
                                    centered={true}
                                >
                                    <Modal.Body
                                        style={{ backgroundColor: "black" }}
                                    >
                                        {film.videos.results.length > 0 ? (
                                            <iframe
                                                src={`https://www.youtube.com/embed/${film.videos.results[0].key}`}
                                                width="853"
                                                height="480"
                                                allowFullScreen
                                                title="bandeAnnonce"
                                            ></iframe>
                                        ) : (
                                            <h1>
                                                Bande annonce non disponible
                                            </h1>
                                        )}
                                    </Modal.Body>
                                </Modal>
                            </Row>
                        </div>
                    </div>

                    <Acteurs id={id} />
                </div>
                <Caroussel
                    subject={"discover/movie"}
                    withGenres={film.genres[0].id}
                    slideNbr={5}
                    title={"Vous aimerez aussi"}
                />
            </div>
            <Footer />
        </>
    );
};

export default FicheFilm;
