import "./App.css";
import Accueil from "./pages/Accueil";
import FicheFilm from "./pages/FicheFilm";
import ResultatsRecherche from "./pages/ResultatsRecherche";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Categories from "./pages/Categories";
import FicheActeur from "./pages/FicheActeur";
import Navigation2 from "./components/Navigation2";
import Films from "./pages/Films";
import Nouveautes from "./pages/Nouveautes";

function App() {
    return (
        <BrowserRouter>
            <Navigation2 />
            <Switch>
                <Route path="/" exact component={Accueil}></Route>
                <Route
                    path="/recherche/:query?/:page?"
                    component={ResultatsRecherche}
                ></Route>
                <Route path="/films/:page?" exact component={Films}></Route>
                <Route
                    path="/categorie/:page?/:categorieId?"
                    exact
                    component={Categories}
                ></Route>
                <Route
                    path="/:id/ficheacteur"
                    exact
                    component={FicheActeur}
                ></Route>
                <Route
                    path="/:id/fichefilm"
                    exact
                    component={FicheFilm}
                ></Route>
                <Route
                    path="/nouveautes/:page?"
                    exact
                    component={Nouveautes}
                ></Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
