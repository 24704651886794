// Image resizable avec source modifiable, utilisée pour les photos d'acteurs, les posters de films
import React from "react";

const Poster = (props) => {
    const { poster_path, width, title, id, classList } = props;
    let src = `https://image.tmdb.org/t/p/${
        width ? "w" + width : "original"
    }${poster_path}`;
    let style;
    if (poster_path === null) {
        src = "/placeholder.jpg";
        style = { width: width };
        // src = `https://via.placeholder.com/${width}x${
        //     width * 1.5
        // }?text=${title}`;
    }
    if (!props) {
        return null;
    }
    // return width <= 200 ? (
    //     <a href={`/${id}/fichefilm`}>
    //         <img src={src} alt={title} />
    //     </a>
    // ) : (
    //     <img src={src} alt={title} className={classList} />
    // );

    return width <= 300 ? (
        <div className={classList}>
            <a href={`/${id}/fichefilm`}>
                <img src={src} alt={title} style={style} />
            </a>
        </div>
    ) : (
        <img src={src} alt={title} className={classList} />
    );
};

export default Poster;
