import React from "react";
import { useHistory } from "react-router-dom";

const Pagination = (props) => {
    const { counter, pagesReponses, recherche } = props;
    let pagesArr = [];
    const history = useHistory();

    console.log(`counter: ${counter}`);
    console.log(`pagesReponses: ${pagesReponses}`);

    for (let i = 1; i <= pagesReponses; i++) {
        pagesArr.push(i);
    }

    let lastPage = pagesReponses > 500 ? 500 : pagesArr[pagesArr.length - 1];

    if (pagesArr.length > 5) {
        pagesArr.splice(5, pagesArr.length - 1, ". . .", lastPage);
    }

    return counter < 5 ? (
        pagesArr.map((page) => (
            <li className={counter === page ? "active page-item" : "page-item"}>
                <span
                    className={"page-link"}
                    onClick={() => {
                        page !== ". . ." &&
                            history.push(
                                recherche === "categorie" ||
                                    recherche === "films" ||
                                    recherche === "nouveautes"
                                    ? `/${recherche}/${page}`
                                    : `/recherche/${recherche}/${page}`
                            );
                    }}
                >
                    {page}
                </span>
            </li>
        ))
    ) : (
        <>
            <li className="page-item">
                <span
                    className={"page-link"}
                    onClick={() => {
                        history.push(
                            recherche === "categorie" ||
                                recherche === "films" ||
                                recherche === "nouveautes"
                                ? `/${recherche}/${counter - 3}`
                                : `/recherche/${recherche}/${counter - 3}`
                        );
                    }}
                >
                    {counter - 3}
                </span>
            </li>
            <li className="page-item">
                <span
                    className={"page-link"}
                    onClick={() => {
                        history.push(
                            recherche === "categorie" ||
                                recherche === "films" ||
                                recherche === "nouveautes"
                                ? `/${recherche}/${counter - 2}`
                                : `/recherche/${recherche}/${counter - 2}`
                        );
                    }}
                >
                    {counter - 2}
                </span>
            </li>
            <li className="page-item">
                <span
                    className={"page-link"}
                    onClick={() => {
                        history.push(
                            recherche === "categorie" ||
                                recherche === "films" ||
                                recherche === "nouveautes"
                                ? `/${recherche}/${counter - 1}`
                                : `/recherche/${recherche}/${counter - 1}`
                        );
                    }}
                >
                    {counter - 1}
                </span>
            </li>
            <li className="page-item active">
                <span
                    className={"page-link "}
                    onClick={() => {
                        history.push(
                            recherche === "categorie" ||
                                recherche === "films" ||
                                recherche === "nouveautes"
                                ? `/${recherche}/${counter}`
                                : `/recherche/${recherche}/${counter}`
                        );
                    }}
                >
                    {counter}
                </span>
            </li>
            {counter <= lastPage - 1 && (
                <li className="page-item">
                    <span
                        className={"page-link"}
                        onClick={() => {
                            history.push(
                                recherche === "categorie" ||
                                    recherche === "films" ||
                                    recherche === "nouveautes"
                                    ? `/${recherche}/${counter + 1}`
                                    : `/recherche/${recherche}/${counter + 1}`
                            );
                        }}
                    >
                        {counter + 1}
                    </span>
                </li>
            )}
            {counter <= lastPage - 2 && (
                <li className="page-item">
                    <span className={"page-link"}>
                        {pagesArr[pagesArr.length - 2]}
                    </span>
                </li>
            )}
            {counter <= lastPage - 2 && (
                <li className="page-item">
                    <span
                        className={"page-link"}
                        onClick={() => {
                            history.push(
                                recherche === "categorie" ||
                                    recherche === "films" ||
                                    recherche === "nouveautes"
                                    ? `/${recherche}/${
                                          pagesArr[pagesArr.length - 1]
                                      }`
                                    : `/recherche/${
                                          pagesArr[pagesArr.length - 1]
                                      }`
                            );
                        }}
                    >
                        {pagesArr[pagesArr.length - 1]}
                    </span>
                </li>
            )}
        </>
    );
};

export default Pagination;
