import React from "react";
import NowPlaying from "../components/NowPlaying";
import Pagination from "../components/Pagination";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Nouveautes = () => {
    const { page = 1 } = useParams();
    const [counter, setCounter] = useState(1);

    const [pagesReponses, setPagesReponses] = useState(0);

    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/movie/now_playing?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA&region=CA&page=1`
            )
            .then((res) => {
                setPagesReponses(res.data.total_pages);
            });
    }, [counter]);
    useEffect(() => {
        setCounter(parseInt(page));
    }, [page]);

    const pageSuivante = () => {
        if (counter < pagesReponses) {
            setCounter(counter + 1);
        }
    };

    const pagePrecedente = () => {
        if (counter > 1) {
            setCounter(counter - 1);
        }
    };
    return (
        <>  <div class="categoriestop">
            <h1 class="txt-open-sans txt-bold txt-40 txt-white txt-30-mobile mr-4">Nouveautés</h1>
            </div>
            <div className="text-center">
                <NowPlaying counter={parseInt(counter)} />
            </div>
            {/* debut pagination */}
            <nav className="Page navigation">
                <ul
                    className={
                        "pagination pagination-lg justify-content-center align-items-center"
                    }
                >
                    <li className="page-item">
                        <span onClick={pagePrecedente}>
                            <img
                                src="/pagination_gauche.png"
                                alt="page precedente"
                            />{" "}
                        </span>
                    </li>
                    <Pagination
                        counter={parseInt(counter)}
                        pagesReponses={pagesReponses}
                        recherche={"nouveautes"}
                    />
                    <li className="page-item">
                        <span onClick={pageSuivante}>
                            <img
                                src="/pagination_droit.png"
                                alt="page suivante"
                            />{" "}
                        </span>
                    </li>
                </ul>
            </nav>
            {/* fin pagination */}
        </>
    );
};

export default Nouveautes;
