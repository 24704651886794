import React, { useState, useEffect } from "react";
import axios from "axios";
import Poster from "./Poster";
// Carousel.js
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { act } from "react-dom/test-utils";

const CarousselFicheActeur = (props) => {
    const { movies } = props;

    const [sliderRef, setSliderRef] = useState(null);

    const sliderSettings = {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        centerPadding: "60px",
        autoplay: false,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    if (!movies) {
        return null;
    }

    return (
        <>
            <div class="container-fluid mx-0  section-site">
                <div class="row menupadding">
                    <div class="col">
                        <h2 class="txt-center-mobile section-padding-mobile">
                            <a
                                href="#"
                                title="TITRE"
                                class="txt-open-sans txt-bold txt-40 txt-white txt-30-mobile"
                            >
                                Célèbre pour
                            </a>
                        </h2>
                    </div>
                </div>

                <div class="row px-0 relative">
                    <span class="prev" onClick={sliderRef?.slickPrev}>
                        <i class="fa-solid fa-chevron-left"></i>
                    </span>
                    <span class="next" onClick={sliderRef?.slickNext}>
                        <i class="fa-solid fa-chevron-right"></i>
                    </span>
                    <Slider ref={setSliderRef} {...sliderSettings}>
                        {movies.map((movie) => (
                            <Poster
                                {...movie}
                                key={movie.id}
                                width={300}
                                classList={"col text-center px-1 imgspacer"}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default CarousselFicheActeur;
