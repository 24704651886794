import React, { useState, useEffect } from "react";
import Categorie from "../components/Categorie";
import PopularMovies from "../components/PopularMovies";
import Footer from "../components/Footer";
import axios from "axios";

const Films = () => {
    const [genre_ids, setGenreIds] = useState("popular");
    const [listeCategories, setListeCategories] = useState([]);
    const [titre, setTitre] = useState("");
    const [discover, setDiscover] = useState("");
    const [sortBy, setSortBy] = useState("");

    const changeCategorie = (e) => {
        setGenreIds(e.target.value);
    };

    const changeDiscover = (e) => {
        setDiscover(e.target.value);
    };

    // va chercher la liste des genres
    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/genre/movie/list?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA`
            )
            .then((res) => {
                setListeCategories(res.data.genres);
            });
    }, []);

    console.log(listeCategories);

    return (
        <>
            <div className="categorieswrapper">
                <div className="categoriestop menupadding">
                    <label
                        for="genres"
                        class="txt-open-sans txt-bold txt-40 txt-white txt-30-mobile mr-4"
                    >
                        Films
                    </label>
                    <select
                        class="select minimal"
                        name="genres"
                        id="genres"
                        value={genre_ids}
                        onChange={changeCategorie}
                    >
                        <option class="option" value="popular">Tous les films</option>
                        <option class="option" value={"now_playing"}>À l'affiche</option>
                        <option class="option" value={"upcoming"}>À venir</option>
                        <option class="option" value={"vote_average.desc"}>
                            Les mieux notés
                        </option>

                        {listeCategories.map((cat) => (
                            <option class="option" value={cat.id}>{cat.name}</option>
                        ))}
                    </select>
                </div>
                <div
                    class="categoriecontent"
                    style={{ backgroundImage: "url(/texture1.png)" }}
                >
                    

                    <Categorie
                        genre_ids={genre_ids}
                        discover={discover}
                        sortBy={sortBy}
                        recherche={"films"}
                        classListPoster={"text-center"}
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Films;
