import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Pagination from "../components/Pagination";
import Poster from "../components/Poster";

const ResultatsRecherche = () => {
    let { query, page } = useParams();
    const [recherche, setRecherche] = useState(query);
    const [resultats, setResultat] = useState([]);
    const [counter, setCounter] = useState(1);
    const [pagesReponses, setPagesReponses] = useState("");
    const history = useHistory();

    const getRecherche = (e) => {
        setRecherche(e.target.value);
    };

    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/search/movie?api_key=1761a627041b994f3b2489ffcaa486f0&query=${query}&language=fr&page=${
                    page ? page : counter
                }`
            )
            .then((res) => {
                setRecherche(query);
                setPagesReponses(res.data.total_pages);
                setResultat(res.data.results);
                if (page) {
                    setCounter(parseInt(page));
                }
            });
    }, [query, page]);

    const rechercheAPI = (page = 1) => {
        // e.preventDefault();
        axios
            .get(
                `https://api.themoviedb.org/3/search/movie?api_key=1761a627041b994f3b2489ffcaa486f0&query=${recherche}&language=fr&page=${page}`
            )
            .then((res) => {
                setCounter(page);
                setPagesReponses(res.data.total_pages);
                setResultat(res.data.results);
            });

        // setCounter(counter + 1);
        console.log(`counter:${counter}`);
    };

    const pageSuivante = () => {
        if (counter < pagesReponses) {
            rechercheAPI(counter + 1);
        }
    };

    const pagePrecedente = () => {
        if (counter > 1) {
            rechercheAPI(counter - 1);
        }
    };

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            history.push(`/recherche/${recherche}`);
        }
    };

    return (
        <div>
            <div className="pagination">
                <span onClick={pagePrecedente}>page précedente </span>
                <span onClick={pageSuivante}>page suivante</span>
            </div>

            {query === undefined && counter === 1 ? (
                <h2>Veuillez rechercher un film</h2>
            ) : (
                <div className="categorie container-fluid  mx-0 sectionfilm">
                    <div className="txt-open-sans txt-bold txt-40 txt-white txt-30-mobile mr-4">
                        Résultats de recherche
                    </div>
                    <div className="row row-cols-3 row-cols-lg-5 image-film  mx-0">
                        {resultats.map((resultat) => (
                            <Poster
                                {...resultat}
                                key={resultat.id}
                                width={300}
                                classList={"col text-center"}
                            />
                        ))}
                    </div>
                </div>
            )}
            {/* debut pagination */}
            <nav className="Page navigation">
                <ul
                    className={
                        "pagination pagination-lg justify-content-center align-items-center"
                    }
                >
                    <li className="page-item">
                        <span onClick={pagePrecedente}>
                            <img
                                src="/pagination_gauche.png"
                                alt="page precedente"
                            />{" "}
                        </span>
                    </li>
                    <Pagination
                        counter={parseInt(counter)}
                        pagesReponses={pagesReponses}
                        recherche={recherche}
                    />
                    <li className="page-item">
                        <span onClick={pageSuivante}>
                            <img
                                src="/pagination_droit.png"
                                alt="page suivante"
                            />{" "}
                        </span>
                    </li>
                </ul>
            </nav>
            {/* fin pagination */}
        </div>
    );
};

export default ResultatsRecherche;
