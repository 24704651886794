// Hero section de la page d'accueil
import React, { useState, useEffect } from "react";
import axios from "axios";

const Hero = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(
                "https://api.themoviedb.org/3/movie/upcoming?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA&page=1"
            )
            .then((res) => setData(res.data.results[2]));
    }, []);

    let src = `https://image.tmdb.org/t/p/original${data.backdrop_path}`;

    return (
        <>
            <div
                className="container-fluid p-0 mt-0 mx-0 banner"
                style={{
                    backgroundImage: `url(${src})`,
                    backgroundSize: "cover",
                    backgound: "center, center"
                }}
            >
                <h1>{/*data.title*/}</h1>
                <a className="btn-nouveautes" href={`/${data.id}/fichefilm`}>
                    Voir plus
                </a>
            </div>
        </>
    );
};

export default Hero;
