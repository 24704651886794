import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

const SearchBar = () => {
    const [recherche, setRecherche] = useState("");
    const history = useHistory();

    const getRecherche = (e) => {
        setRecherche(e.target.value);
    };

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            history.push(`/recherche/${recherche}`);
        }
    };
    return (
        <div className="">
            <div className="collapse mx-3" id="navbarToggleExternalContent">
                <nav className="navbar navbar-light">
                    <form
                        id="rechercheForm"
                        onSubmit={(e) => e.preventDefault()}
                        className="form-inline w-100 search-form zonerecherche"
                    >
                        <div className="input-group w-100">
                            <div className="input-group-prepend">
                                <button
                                    className="input-group-text bginput border-none"
                                    id="basic-addon1"
                                    type="button"
                                    onClick={() =>
                                        history.push(`/recherche/${recherche}`)
                                    }
                                >
                                    <i class="fa-solid fa-magnifying-glass txt-black"></i>
                                </button>
                            </div>

                            <input
                                type="text"
                                className="form-control bginput border-none"
                                placeholder="Rechercher"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="recherche"
                                onChange={getRecherche}
                                onKeyUp={handleEnter}
                            ></input>
                            <div className="input-group-append">
                                <button
                                    className="input-group-text bginput border-none"
                                    id="basic-addon1"
                                    type="submit"
                                    data-toggle="collapse"
                                    data-target="#navbarToggleExternalContent"
                                    aria-controls="navbarToggleExternalContent"
                                    aria-expanded="false"
                                >
                                    <i className="fa-solid fa-xmark txt-black"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </nav>
            </div>
        </div>
    );
};

export default SearchBar;
