import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActeurInfo from "../components/ActeurInfo";
import Profile from "../components/Profile";
import CarousselFicheActeur from "../components/CarousselFicheActeur";
import Footer from "../components/Footer";

const FicheActeur = () => {
    const { id } = useParams();
    const [acteur, setActeur] = useState({});
    const [movies, setMovies] = useState([]);

    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/person/${id}?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA&append_to_response=combined_credits`
            )
            .then((res) => {
                setActeur(res.data);
            });
    }, []);

    useEffect(() => {
        setMovies(acteur.combined_credits);
    }, [acteur]);

    if (!movies) {
        return null;
    }

    return (
        <>
            <div
                class="fiche-acteur"
                style={{ backgroundImage: "url(/arriere_plan_rideaux.jpg)" }}
            >
                <div class="container-fluid mx-0">
                    <div class="row mx-0 ">
                        <div class="col-12 col-lg-4 text-center pb-3">
                            <Profile {...acteur} width={500} />
                        </div>
                        <ActeurInfo {...acteur} />
                    </div>
                </div>
            </div>
            <CarousselFicheActeur acteur={acteur} movies={movies.cast} />
            <Footer />
        </>
    );
};

export default FicheActeur;
