// Liste des acteurs présents dans un film, présente dans la page ficheFilm
import axios from "axios";
import React, { useEffect, useState } from "react";
import Profile from "./Profile";
// Carousel.js
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Acteurs = (props) => {
    const { id } = props;
    const [acteurs, setActeurs] = useState([]);
    useEffect(() => {
        axios
            .get(
                `https://api.themoviedb.org/3/movie/${id}/credits?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA`
            )
            .then((res) => setActeurs(res.data.cast));
    });
    const sliderSettings2 = {
        slidesToShow: acteurs.length > 8 ? 8 : acteurs.length,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        centerPadding: "60px",
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: acteurs.length > 7 ? 7 : acteurs.length
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: acteurs.length > 5 ? 5 : acteurs.length
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: acteurs.length > 3 ? 3 : acteurs.length
                }
            }
        ]
    };
    const [sliderRef, setSliderRef2] = useState(null);
    return (
        <div
            class="container-fluid mx-0  section-site bg-rideaux"
            style={{ backgroundImage: "url(/arriere_plan_rideaux.jpg)" }}
        >
            <div class="row menupadding">
                <div class="col">
                    <h2 class="txt-center-mobile section-padding-mobile">
                        <a
                            href="#"
                            title="TITRE"
                            class="txt-open-sans txt-bold txt-40 txt-white txt-30-mobile"
                        >
                            Distribution
                        </a>
                    </h2>
                </div>
            </div>

            <div class="row px-0 relative">
                <span class="prev" onClick={sliderRef?.slickPrev}>
                    <i class="fa-solid fa-chevron-left"></i>
                </span>
                <span class="next" onClick={sliderRef?.slickNext}>
                    <i class="fa-solid fa-chevron-right"></i>
                </span>
                <Slider ref={setSliderRef2} {...sliderSettings2}>
                    {acteurs.map((acteur) => (
                        <div class="col text-center px-1 imgspacer">
                            <Profile {...acteur} key={acteur.id} width={200} />
                            <div class="bg-white py-2 px-1">
                                <h4 class="txt-18 pt-1">{acteur.name}</h4>
                                <p>{acteur.character}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Acteurs;
