import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Pagination from "./Pagination";
import Poster from "./Poster";

const Categorie = (props) => {
    const { genre_ids, recherche, sortBy, classListPoster } = props;
    const { page } = useParams();
    const [categorie, setCategorie] = useState([]);
    const [counter, setCounter] = useState(1);
    const [pagesReponses, setPagesReponses] = useState(0);

    useEffect(() => {
        if (
            genre_ids === "now_playing" ||
            genre_ids === "upcoming" ||
            genre_ids === "popular"
        ) {
            axios
                .get(
                    `https://api.themoviedb.org/3/movie/${genre_ids}?api_key=1761a627041b994f3b2489ffcaa486f0&language=fr-CA&page=${counter}`
                )
                .then((res) => {
                    setCategorie(res.data.results);
                    setPagesReponses(res.data.total_pages);
                });
        } else {
            axios
                .get(
                    `https://api.themoviedb.org/3/discover/movie?api_key=1761a627041b994f3b2489ffcaa486f0${
                        parseInt(genre_ids) ? `&with_genres=${genre_ids}` : ""
                    }${
                        sortBy && `&sort_by=${sortBy}`
                    }&language=fr-CA&page=${counter}`
                )
                .then((res) => {
                    setCategorie(res.data.results);
                    setPagesReponses(res.data.total_pages);
                });
        }
    }, [genre_ids, counter, sortBy]);

    useEffect(() => {
        setCounter(parseInt(page));
    }, [page]);

    const pageSuivante = () => {
        if (counter < pagesReponses) {
            setCounter(counter + 1);
        }
    };

    const pagePrecedente = () => {
        if (counter > 1) {
            setCounter(counter - 1);
        }
    };

    return (
        <div className="categorie container-fluid  mx-0 sectionfilm">
            <div className="row row-cols-3 row-cols-lg-5 image-film  mx-0">
                {categorie.map((film) => (
                    <Poster
                        {...film}
                        key={film.id}
                        width={300}
                        classList={classListPoster}
                    />
                ))}
            </div>
            {/* debut pagination */}
            <nav className="Page navigation">
                <ul
                    className={
                        "pagination pagination-lg justify-content-center align-items-center"
                    }
                >
                    <li className="page-item">
                        <span onClick={pagePrecedente}>
                            <img
                                src="/pagination_gauche.png"
                                alt="page precedente"
                            />{" "}
                        </span>
                    </li>
                    <Pagination
                        counter={parseInt(counter)}
                        pagesReponses={pagesReponses}
                        recherche={recherche}
                    />
                    <li className="page-item">
                        <span onClick={pageSuivante}>
                            <img
                                src="/pagination_droit.png"
                                alt="page suivante"
                            />{" "}
                        </span>
                    </li>
                </ul>
            </nav>
            {/* fin pagination */}
        </div>
    );
};

export default Categorie;
